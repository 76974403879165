import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { i18n } from './inlineCommentsi18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapsedRepliesContainer = styled.div({
	borderTop: `1px solid ${token('color.border')}`,
	textAlign: 'center',
	height: '29px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DecorLine = styled.div({
	height: '9px',
	borderBottom: `1px solid ${token('color.border')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapsedRepliesTextContainer = styled.div({
	position: 'relative',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		top: `${token('space.negative.200')}`,
		cursor: 'pointer',
		padding: `${token('space.0')} ${token('space.075')}`,
		backgroundColor: `${token('elevation.surface')}`,
		position: 'relative',
	},
});

type CollapsedRepliesProps = {
	numCollapsedReplies: number;
	onClick: () => void;
};

export const CollapsedReplies: FC<CollapsedRepliesProps> = ({ numCollapsedReplies, onClick }) => {
	const message = {
		...i18n.collapsedRepliesMore,
		values: { numCollapsedReplies },
	};
	return (
		<CollapsedRepliesContainer>
			<DecorLine />
			<DecorLine />
			<CollapsedRepliesTextContainer data-testid="collapsed-inline-replies" onClick={onClick}>
				<FormattedMessage {...message} />
			</CollapsedRepliesTextContainer>
		</CollapsedRepliesContainer>
	);
};
