import { expValEquals } from '@confluence/feature-experiments';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';

type UseGetCommentReplyPromptsEligibilityProps = {
	contentId: string;
};

//  Eligibility hook for suggested comment reply prompts experiment: https://hello.atlassian.net/wiki/spaces/Comments/pages/3579415601/Project+Poster+EXP+Suggested+Comment+Prompts+for+Inline+Comment+Replies
export const useGetCommentReplyPromptsEligibility = ({
	contentId,
}: UseGetCommentReplyPromptsEligibilityProps): boolean => {
	const { isAIEnabledForContent } = useIsAIEnabledForContent({ contentId });

	return (
		isAIEnabledForContent &&
		expValEquals<string>('cc_suggested_inline_comment_replies', 'cohort', 'test')
	);
};
