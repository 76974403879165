/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';

import type {
	InlineCommentLocation,
	InlineCommentQueryNode,
} from '@confluence/inline-comments-queries';
import { useOnScreen, type RefObject } from '@confluence/inline-comments-hooks';
import { createSingleQueryParamHook } from '@confluence/route-manager';
import { handleScrollToElement } from '@confluence/comments-util';

import type {
	useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation as useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation$data,
	useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutationVariables as useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation$variables,
} from './__types__/useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation';
import { useUnreadComments } from './useUnreadComments';

type UnreadCommentsProps = {
	commentData: InlineCommentQueryNode | null | undefined;
	isEditor: boolean;
	focusedCommentId: string | null;
	shouldCollapseReplies: boolean;
	setIsReplyChainCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
	isInlineCommentVisible: boolean;
	onUpdateUnreadStatus: (commentIds: Set<string>) => void;
};

export const useUnreadCommentsForInlineComment = ({
	commentData,
	isEditor,
	focusedCommentId,
	shouldCollapseReplies,
	setIsReplyChainCollapsed,
	isInlineCommentVisible,
	onUpdateUnreadStatus,
}: UnreadCommentsProps) => {
	const [
		{ unreadCommentsListState, readCommentsListState },
		{ updateUnreadCommentsListState, updateReadCommentsListState },
	] = useUnreadComments();

	const useUnreadCommentsQueryParam = createSingleQueryParamHook('unreadCommentMark');
	const unreadCommentMark = useUnreadCommentsQueryParam();

	// this will show the unread badge for unread comments
	const [currentUnreadIds, setCurrentUnreadIds] = useState<Set<string>>(new Set());

	const isUnreadComment = useCallback((commentIdToCheck: string, readComments: Set<string>) => {
		return !readComments.has(commentIdToCheck);
	}, []);

	const [markInlineCommentsRead, { error: markCommentReadError }] = useMutation<
		useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation$data,
		useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation$variables
	>(gql`
		mutation useUnreadCommentsForInlineCommentMarkInlineCommentsReadMutation($commentIds: [ID!]!) {
			markCommentsAsRead(input: { commentIds: $commentIds }) {
				success
				errors
			}
		}
	`);
	// store the top level comment and replies into a ref array so we can check if they are unread and in viewport
	const commentRefArray = useRef<(React.RefObject<any> | null)[]>([]);
	useEffect(() => {
		commentRefArray.current = commentRefArray.current.slice(
			0,
			(commentData?.replies?.length || 0) + 1,
		);
	}, [commentData]);

	const unreadCommentRefs: RefObject[] = useMemo(() => {
		// Filter out read comments
		const unreadComments = [
			commentData?.id,
			...(commentData?.replies?.map((reply) => reply?.id) || []),
		].filter((id) => id && isUnreadComment(id, readCommentsListState));

		return unreadComments.map((id, i) => {
			return {
				ref: (commentRefArray.current[i] ||= React.createRef()),
				id: id ?? '',
			};
		});
	}, [commentData, isUnreadComment, readCommentsListState]);

	const unreadVisibleCommentIds = useOnScreen({
		refs: unreadCommentRefs,
		shouldSkip: currentUnreadIds.size === 0,
	});

	// handle unread comments/replies display
	useEffect(() => {
		if (commentData) {
			const allCommentIds = new Set<string>();
			allCommentIds.add(commentData.id);

			commentData.replies?.forEach((reply) => {
				if (reply) {
					allCommentIds.add(reply.id);
				}
			});
			const currentThreadUnreadCommentIds: Set<string> = new Set();
			const filteredUnreadComments = unreadCommentsListState.filter((unreadComment) => {
				const isUnreadCommentInCommentIds = allCommentIds.has(unreadComment.id);
				if (isUnreadCommentInCommentIds) {
					currentThreadUnreadCommentIds.add(unreadComment.id);
				}
				return !isUnreadCommentInCommentIds;
			});
			// Update the state with the filtered unread comments
			updateUnreadCommentsListState(() => filteredUnreadComments);

			setCurrentUnreadIds(currentThreadUnreadCommentIds);

			if (currentThreadUnreadCommentIds.size) {
				const newReadCommentsListState = new Set([
					...readCommentsListState,
					...currentThreadUnreadCommentIds,
				]);
				updateReadCommentsListState(() => newReadCommentsListState);
				void markInlineCommentsRead({
					variables: { commentIds: [...currentThreadUnreadCommentIds] },
				});

				onUpdateUnreadStatus(currentThreadUnreadCommentIds);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentData]);

	// handle updating unread count and state
	useEffect(() => {
		if (commentData) {
			// only mark the comments/replies in view as read and update unread/read lists accordingly
			const commentsToMarkRead: Set<string> = new Set();
			if (unreadVisibleCommentIds.size) {
				updateUnreadCommentsListState((prev) => {
					return prev.filter((unreadComment) => {
						if (unreadVisibleCommentIds.has(unreadComment.id)) {
							commentsToMarkRead.add(unreadComment.id);
							return false;
						}
						return true;
					});
				});
			}

			if (commentsToMarkRead.size) {
				// TODO: mark the unread comments as read
				// updateReadCommentsListState(
				//   (prev) => new Set([...prev, ...commentsToMarkRead])
				// );
				// void markInlineCommentsRead({
				//   variables: { commentIds: [...commentsToMarkRead] },
				// });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- updateUnreadCommentsListState in the dependency array will cause unnecessary re-renders
	}, [commentData, unreadVisibleCommentIds]);

	useEffect(() => {
		if (shouldCollapseReplies && commentData?.replies?.length) {
			const hiddenReplies = commentData?.replies.slice(0, -1);
			if (hiddenReplies && hiddenReplies.find((reply) => reply && currentUnreadIds.has(reply.id))) {
				setIsReplyChainCollapsed(false);
			}
		}
	}, [commentData?.replies, currentUnreadIds, shouldCollapseReplies, setIsReplyChainCollapsed]);

	useEffect(() => {
		const markerRef = (commentData?.location as InlineCommentLocation)?.inlineMarkerRef;

		if (
			unreadCommentMark === markerRef &&
			!focusedCommentId &&
			currentUnreadIds.size &&
			isInlineCommentVisible
		) {
			const [firstUnreadId] = currentUnreadIds;
			handleScrollToElement({
				commentId: firstUnreadId,
				targetCommentId: firstUnreadId,
				isEditor: Boolean(isEditor),
				viewingRoomOffset: -300,
			});
		}
	}, [
		currentUnreadIds,
		focusedCommentId,
		isEditor,
		commentData?.location,
		unreadCommentMark,
		isInlineCommentVisible,
	]);

	return {
		unreadCommentRefs,
		currentUnreadIds,
		markCommentReadError,
	};
};
