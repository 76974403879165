import type { FC } from 'react';
import React, { Fragment, useCallback, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import CheckCircleIcon from '@atlaskit/icon/core/check-circle';

import {
	UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { getLogger } from '@confluence/logger';
import {
	CommentButtonRefetchQuery,
	ResolveInlineCommentMutation,
} from '@confluence/inline-comments-queries';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

import { ResolvedNotificationContainer } from './styled-components';
import type { InlineCommentsMode } from './inlineCommentsTypes';
import { CommentErrorMessage } from './CommentErrorMessage';
import { InlineCommentMode } from './enum/InlineCommentMode';
import { InlineCommentFramework } from './enum/InlineCommentFramework';

const i18n = defineMessages({
	resolvedCommentDescription: {
		id: 'inline-comments-common.resolved-comment-description',
		description: 'Message to be displayed when user Resolves a comment',
		defaultMessage: 'You can view all the comments from the More actions menu.',
	},
	resolvedCommentTitle: {
		id: 'inline-comments-common.resolved-comment-title',
		description: 'Title to be displayed when user Resolves a comment',
		defaultMessage: 'Comment Resolved',
	},
});

type ResolvedCommentNotificationProps = {
	commentId: string;
	reopenComment: (markerRef?: string) => void;
	pageId: string;
	inlineMarkerRef: string;
	mode: InlineCommentsMode;
};

const logger = getLogger('resolved-comment-notification');

export const ResolvedCommentNotification: FC<ResolvedCommentNotificationProps> = ({
	commentId,
	reopenComment,
	pageId,
	inlineMarkerRef,
	mode,
}) => {
	const refetchQueries =
		mode !== 'view-all' ? [{ query: CommentButtonRefetchQuery, variables: { pageId } }] : [];

	const [reopenInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveInlineCommentMutation,
		{
			refetchQueries,
		},
	);

	const [error, setError] = useState<Error | null>(null);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const isLivePage = useIsCurrentPageLive();

	const getCommentModeForAnalytics = useCallback(() => {
		return mode === InlineCommentMode.EDIT
			? isLivePage
				? InlineCommentMode.LIVE
				: InlineCommentMode.EDIT
			: InlineCommentMode.VIEW;
	}, [mode, isLivePage]);

	const unresolveComment = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();

			const mtnVariables = {
				variables: { commentId, resolved: false },
			};

			experienceTracker.start({
				name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
				id: commentId,
				attributes: {
					mode: getCommentModeForAnalytics(),
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				},
			});

			reopenInlineCommentFn({
				...mtnVariables,
			})
				.then(() => {
					reopenComment(inlineMarkerRef);

					experienceTracker.succeed({
						name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
					});
				})
				.catch((error) => {
					logger.error`An error occurred when reopening a comment ${error}`;

					setError(error);
					experienceTracker.stopOnError({
						name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
						error,
					});
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			commentId,
			reopenComment,
			reopenInlineCommentFn,
			inlineMarkerRef,
			experienceTracker,
			getCommentModeForAnalytics,
			mode,
		],
	);

	return (
		<Fragment>
			<ResolvedNotificationContainer>
				<CheckCircleIcon
					color={token('color.icon.success')}
					label=""
					spacing="spacious"
					testId="inline-comments.confirm-icon"
				/>
				<Heading size="xsmall">
					<FormattedMessage {...i18n.resolvedCommentTitle} />
				</Heading>
				<FormattedMessage {...i18n.resolvedCommentDescription} tagName="p" />
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<a onClick={unresolveComment}>
					<FormattedMessage
						id="inline-comments-common.undo-resolved-comment"
						defaultMessage="Undo resolved comment"
						description="Undo Resolved comment"
					/>
				</a>
			</ResolvedNotificationContainer>
			{error && <CommentErrorMessage error={error} action="reopen" />}
		</Fragment>
	);
};
