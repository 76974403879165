import { useState, useEffect, useRef } from 'react';

export type RefObject = {
	ref: React.RefObject<HTMLDivElement>;
	id: string;
};

type OnScreenProps = {
	refs: RefObject[];
	shouldSkip?: boolean;
};

// function that accepts an array of refs and returns the ones that are at least 50% visible
export const useOnScreen = ({ refs, shouldSkip = false }: OnScreenProps) => {
	const [visibleIds, setVisibleIds] = useState<Set<string>>(new Set());
	const options = useRef({
		root: null, // viewport
		rootMargin: '0px',
		threshold: 0.5, // 50% visible
	});

	useEffect(() => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR || shouldSkip || !refs.every(({ ref }) => ref.current)) {
			// Not all elements are mounted yet, exit early
			return;
		}

		// eslint-disable-next-line
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				// Find the corresponding ref based on the entry target
				const refObject = refs.find(({ ref }) => ref.current === entry.target);

				// If the corresponding ref is not found, don't do anything
				if (!refObject) return;

				setVisibleIds((prev) => {
					const newVisibleIds = new Set(prev);
					const idExists = newVisibleIds.has(refObject.id);

					if (entry.isIntersecting) {
						// ensure id is not already in the list before adding
						if (!idExists) {
							newVisibleIds.add(refObject.id);
						}
					} else {
						// remove id from list if it's no longer visible
						if (idExists) {
							newVisibleIds.delete(refObject.id);
						}
					}

					return newVisibleIds;
				});
			});
		}, options.current);

		refs.forEach(({ ref }) => {
			// only observe if the element is in the dom
			if (ref.current) {
				observer.observe(ref.current);
			}
		});

		// clean up observer when component unmounts
		return () => {
			observer.disconnect();
		};
	}, [refs, shouldSkip]);

	return visibleIds;
};
