import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getLogger } from '@confluence/logger';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	ContentContributorsQuery,
	type ContentContributorsQueryVariables,
	type ContentContributorsQueryType,
} from '@confluence/quick-summary';
import type {
	InlineCommentQueryNode,
	InlineCommentAuthorUser,
} from '@confluence/inline-comments-queries';

import { useGetCommentReplyPromptsEligibility } from './useGetCommentReplyPromptsEligibility';
import { WatchPageQuery } from './WatchPageQuery.graphql';
import type {
	WatchPageQuery as WatchPageQueryData,
	WatchPageQueryVariables,
} from './__types__/WatchPageQuery';

const logger = getLogger('comment-reply-prompts');

export const useShouldDisplayCommentReplyPrompts = ({
	contentId,
	commentData,
	userId,
}: {
	contentId: string;
	commentData: InlineCommentQueryNode | undefined | null;
	userId: string | null;
}): boolean => {
	const isCommentReplyPromptsEligible = useGetCommentReplyPromptsEligibility({ contentId });

	const { data: contributorsData, error: contributorsDataError } = useQuery<
		ContentContributorsQueryType,
		ContentContributorsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentContributorsQuery,
		{
			skip: !isCommentReplyPromptsEligible,
			fetchPolicy: 'cache-first',
			variables: {
				contentId,
			},
		},
	);

	const { data: watchData, error: watchDataError } = useQuery<
		WatchPageQueryData,
		WatchPageQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		WatchPageQuery,
		{
			skip: !isCommentReplyPromptsEligible,
			fetchPolicy: 'cache-first',
			variables: {
				contentId,
			},
		},
	);

	if (contributorsDataError) {
		logger.error`Error fetching ContentContributorsQuery, ${contributorsDataError}`;
		markErrorAsHandled(contributorsDataError);
	}

	if (watchDataError) {
		logger.error`Error fetching WatchPageQuery, ${watchDataError}`;
		markErrorAsHandled(watchDataError);
	}

	const shouldDisplayCommentReplyPrompts: boolean = useMemo(() => {
		if (!isCommentReplyPromptsEligible) {
			return false;
		}
		// if we both queries fail, we can't tell if the user is a watcher or contributor and we should not show the prompts
		if (watchDataError && contributorsDataError) {
			return false;
		}

		const isCurrentUserContributor: boolean =
			contributorsData?.contentContributors?.isCurrentUserContributor || false;

		const isUserWatchingPage: boolean =
			watchData?.content?.nodes?.[0]?.currentUserIsWatching || false;

		const replies = commentData?.replies || [];

		const topLevelAuthor = commentData?.author as InlineCommentAuthorUser;
		const lastReplyAuthor = replies?.[replies?.length - 1]?.author as InlineCommentAuthorUser;

		const lastAuthorInThread = lastReplyAuthor || topLevelAuthor;

		// if the last author in the thread is anonymous, we should not show the prompts
		if (!lastAuthorInThread?.accountId) {
			return false;
		}

		const userIsNotLastCommenterInThread = lastAuthorInThread.accountId !== userId;

		return userIsNotLastCommenterInThread && (isCurrentUserContributor || isUserWatchingPage);
	}, [
		commentData,
		contributorsData,
		contributorsDataError,
		isCommentReplyPromptsEligible,
		userId,
		watchData,
		watchDataError,
	]);

	return shouldDisplayCommentReplyPrompts;
};
