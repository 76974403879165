import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { TimeAgo } from '@confluence/time-ago';

type ReopenedMessageProps = {
	resolvedUser?: string;
	resolvedTime?: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReopenedMessageContainer = styled.div({
	padding: `5px 18px 5px ${token('space.200')}`,
	borderTop: `1px solid ${token('color.border')}`,
	font: token('font.body.UNSAFE_small'),
});

export const ReopenedMessage: FC<ReopenedMessageProps> = ({ resolvedUser, resolvedTime }) =>
	resolvedUser && resolvedTime ? (
		<ReopenedMessageContainer>
			<FormattedMessage
				id="inline-comments-common.reopened.message"
				defaultMessage="Reopened by {person}"
				description="A message telling the user the comment was reopened and who did it"
				values={{ person: resolvedUser }}
			/>{' '}
			<TimeAgo date={resolvedTime} />
		</ReopenedMessageContainer>
	) : null;
