import type { ApolloQueryResult } from 'apollo-client';

import type { InlineCommentQueryType } from '@confluence/inline-comments-queries';
import { CommentType } from '@confluence/comments-data';
import type { CommentData, CommentsDataMap, ReplyData } from '@confluence/comments-data';
import { InlineCommentQuery } from '@confluence/inline-comments-queries';
import { getApolloClient } from '@confluence/graphql';

export const refreshCommentThread = (
	contentId: string,
	markerRef: string,
): Promise<ApolloQueryResult<any>> => {
	return getApolloClient().query<InlineCommentQueryType>({
		query: InlineCommentQuery,
		fetchPolicy: 'network-only',
		variables: {
			pageId: contentId,
			annotationId: markerRef,
			contentStatus: ['DRAFT', 'CURRENT'],
		},
	});
};

export const fetchCommentThread = async (
	contentId: string,
	markerRef: string,
): Promise<InlineCommentQueryType> => {
	const commentThreadDataResult = await getApolloClient().query<InlineCommentQueryType>({
		query: InlineCommentQuery,
		fetchPolicy: 'network-only',
		variables: {
			pageId: contentId,
			annotationId: markerRef,
			contentStatus: ['DRAFT', 'CURRENT'],
		},
	});
	return commentThreadDataResult.data;
};

export const handleAddNewCommentThread = async ({
	contentId,
	markerRef,
	addNewCommentThreads,
}: {
	contentId: string | undefined;
	markerRef: string;
	addNewCommentThreads: ((newCommentThreads: CommentsDataMap) => void) | undefined;
}) => {
	const commentThreadDataResult = await fetchCommentThread(contentId ?? '', markerRef);
	const comment = commentThreadDataResult?.comments?.nodes?.[0];
	if (comment) {
		addNewCommentThreads &&
			addNewCommentThreads({
				inline: {
					[markerRef]: {
						...comment,
						isUnread: true,
						isOpen: true,
						wasRemovedByAnotherUser: false,
						type: CommentType.INLINE,
					} as CommentData,
				},
				general: {},
			});
	}
};

export const handleAddedReplyInCommentThread = async ({
	contentId,
	markerRef,
	commentId,
	addReplyToCommentThread,
	commentType,
}: {
	contentId: string | undefined;
	markerRef: string;
	commentId: string;
	addReplyToCommentThread:
		| ((parentMarkerRef: string, reply: ReplyData, commentType: CommentType) => void)
		| undefined;
	commentType: CommentType;
}) => {
	const commentThreadDataResult = await fetchCommentThread(contentId ?? '', markerRef);
	const comment = commentThreadDataResult?.comments?.nodes?.[0];
	const replies = comment?.replies;
	const reply = replies?.filter((r) => r?.id === commentId)[0];
	if (reply) {
		addReplyToCommentThread &&
			addReplyToCommentThread(
				markerRef,
				{
					...reply,
					isUnread: true,
				} as ReplyData,
				commentType,
			);
	}
};
